<template>
  <view-employee-time-clock v-if="deviceMode === 'employee-time-clock'" />
  <view-agenda v-else-if="deviceMode === 'agenda'" />
  <view-configuration v-else />
</template>

<script>
import ViewConfiguration from './configuration/ViewConfiguration.vue'
import ViewEmployeeTimeClock from './ViewEmployeeTimeClock.vue'
import ViewAgenda from './ViewAgenda.vue'

export default {
  components: {
    ViewEmployeeTimeClock,
    ViewAgenda,
    ViewConfiguration,
  },

  data() {
    return {}
  },

  computed: {
    deviceMode() {
      return localStorage.getItem('device-mode') || 'configuration'
    },
  },

  methods: {},
}
</script>

<i18n>
{
  "pt": {},
  "en": {}
}
</i18n>
