<template>
  <layout bg-video>
    <template #main>
      <div class="flex-1 flex items-center justify-center h-full">
        <div class="bg-gray-800 bg-opacity-60 backdrop-blur rounded-3xl p-20 min-w-md">
          <!-- Page for not allowed -->
          <div v-if="page === 'error-not-allowed'" class="flex flex-col items-center justify-center gap-10 text-center">
            <div class="text-red-400 font-bold flex flex-col gap-3 justify-center items-center max-w-xs">
              <fw-icon-emotion-sad class="w-24 h-24" />
              <fw-heading size="h2"
                >Não tem permissões para registar a sua assiduidade através deste dispositivo.</fw-heading
              >
            </div>
            <div class="flex flex-col justify-center items-center gap-1">
              <div
                class="w-12 h-12 p-5 rounded-full bg-gray-500 bg-opacity-70 text-gray-100 font-semibold flex items-center justify-center text-lg"
              >
                {{ countDown }}
              </div>
              <fw-button type="link-muted" @click.native="reset()">Fechar janela</fw-button>
            </div>
          </div>
          <!-- Page for success -->
          <div v-else-if="page === 'success'" class="flex flex-col items-center justify-center gap-10 text-center">
            <div class="text-primary font-bold">
              <checkmark-success size="lg" />
              <fw-heading size="h2">Autenticação<br />válida</fw-heading>
            </div>
            <div>
              <div class="font-bold">Continue na</div>
              <fw-icon-uc-one class="w-28 h-20"></fw-icon-uc-one>
            </div>
            <div class="flex flex-col justify-center items-center gap-1">
              <div
                class="w-12 h-12 p-5 rounded-full bg-gray-500 bg-opacity-70 text-gray-100 font-semibold flex items-center justify-center text-lg"
              >
                {{ countDown }}
              </div>
              <fw-button type="link-muted" @click.native="reset()">Fechar janela</fw-button>
            </div>
          </div>
          <!-- Show QRCode -->
          <div v-else class="flex flex-col items-center justify-center gap-5">
            <div class="text-md font-bold text-gray-500 uppercase">Registo de assiduidade</div>
            <BlockPasswordlessLogin
              :key="qrCodeKey"
              class="mx-auto text-gray-500"
              :qrcode-size="275"
              :redirect="false"
              :hide-mobile="false"
              :show-branding="false"
              :show-countdown="true"
              :seconds-to-expire="120"
              :restart-after-expired="true"
              :qr-code-metadata="{
                access_key: accessKey,
                i: 'entry_registry',
              }"
              :dark="true"
              @countdown="qrcodeCountdown = $event"
              @loggedin="postLogin"
            />
            <fw-heading class="flex items-center justify-center">
              Scan with <fw-icon-uc-one class="w-16 h-16 p-2"></fw-icon-uc-one>
            </fw-heading>
          </div>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import BlockPasswordlessLogin from '@/fw-modules/fw-core-vue/id/components/blocks/BlockPasswordlessLogin'
import CheckmarkSuccess from '@/fw-modules/fw-core-vue/ui/components/animation/CheckmarkSuccess'
import ServiceMyUC from '@/services/ServiceMyUC'
import Layout from '@/components/Layout.vue'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  name: 'ViewEmployeeTimeClock',

  components: {
    BlockPasswordlessLogin,
    CheckmarkSuccess,
    Layout,
  },

  data() {
    return {
      page: 'login',
      countDown: 3,
      timer: null,
      currentUser: null,
      qrcodeCountdown: null,
      appVersion: process.env.VUE_APP_VERSION,
      appName: process.env.VUE_APP_KEY,
      qrCodeKey: 0, //This qr code is used to force the component to re-render
    }
  },

  computed: {
    accessKey() {
      return localStorage.getItem('access-key')
    },
    // Checks
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
  },

  methods: {
    // After Passwordless login, check if user is allowed to register presence
    async postLogin() {
      console.log('POST LOGIN')
      utils.tryAndCatch(this, async () => {
        let result = await ServiceMyUC.verifyUserPresence(this.accessKey)

        if (result.registry_allowed === false) {
          this.page = 'error-not-allowed'
        } else {
          this.page = 'success'
        }

        // Start timer and reset
        this.countDown = 5
        this.timer = setInterval(() => {
          this.countDown--
          if (this.countDown === 0) {
            this.reset()
          }
        }, 1000)
      })
    },
    logout() {
      this.$store.dispatch('logoutWithoutRedirect')
    },
    reset() {
      if (this.timer != null) {
        clearInterval(this.timer)
        this.timer = null
      }
      this.qrCodeKey++
      if (this.qrCodeKey > 100) {
        this.qrCodeKey = 0
      }
      this.page = 'login'
      this.mode = null
      this.userData = null
      this.countDown = 3
      this.errors = []
      this.currentUser = null
      this.logout()
    },
  },
}
</script>
