<template>
  <layout>
    <template #main>
      <div class="mx-auto max-w-md h-full flex flex-col gap-5">
        <div v-if="view === 'list'" class="flex flex-col gap-10 flex-1">
          <div>
            <fw-heading>Serviços disponíveis</fw-heading>
            <fw-label>Escolha o serviço que pretende configurar neste dispositivo:</fw-label>
          </div>
          <div class="flex flex-col gap-5">
            <fw-button type="light" size="lg" expanded @click.native="view = 'config-employee-time-clock'">
              Marcação de assiduidade
            </fw-button>
          </div>
          <div class="flex flex-col gap-5">
            <fw-button type="light" size="lg" expanded @click.native="autoConfigAgenda()">
              Agenda de evento
            </fw-button>
          </div>
        </div>
        <view-configuration-employee-time-clock v-else-if="view === 'config-employee-time-clock'" class="flex-1" />
        <view-configuration-agenda v-else-if="view === 'config-agenda'" class="flex-1" />
        <div v-if="view !== 'list'">
          <fw-button type="link-muted" expanded @click.native="view = 'list'">
            Voltar à lista de serviços
          </fw-button>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/Layout.vue'
import ViewConfigurationEmployeeTimeClock from './ViewConfigurationEmployeeTimeClock.vue'
import ViewConfigurationAgenda from './ViewConfigurationAgenda.vue'
export default {
  name: 'ViewConfiguration',

  components: {
    Layout,
    ViewConfigurationEmployeeTimeClock,
    ViewConfigurationAgenda,
  },

  data() {
    return {
      view: 'list',
    }
  },
  methods: {
    autoConfigAgenda() {
      localStorage.setItem('pages-token', 'b7f30645db862abadb6be1405d4f59e9aaacfc0269')
      localStorage.setItem('event-id', '1088866')
      localStorage.setItem('device-mode', 'agenda')
      localStorage.setItem('footer-url', 'https://app.sppcr.pt')
      localStorage.setItem('update-time', 50000)
      localStorage.setItem('carrousel-time', 0)
      this.$router.go()
    },
  },
}
</script>
