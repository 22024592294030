<template>
  <layout :footer="false" screen>
    <template #main>
      <AgendaComponent
        v-if="eventsData"
        :key="day"
        :time-info="timeInfo"
        :page="pageData"
        :event="eventsData"
        :day="day"
      />
      <div v-else class="flex flex-col justify-center items-center absolute top-1/2 left-1/2 -mt-5 -ml-14">
        <div class="text-3xl font-semibold text-white text-center">Loading...</div>
      </div>
    </template>
  </layout>
</template>

<script>
import ServiceEvents from '@/services/ServiceEvents'
import AgendaComponent from '@/components/AgendaComponent.vue'
import Layout from '@/components/Layout.vue'
//import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  name: 'ViewAgenda',

  components: {
    Layout,
    AgendaComponent,
  },

  data() {
    return {
      pageData: null,
      eventsData: null,
      day: 0,
      roulleteTimer: null,
      timeInfo: {
        monthDay: 1,
        month: 0,
        year: 2023,
        hours: 0,
        minutes: 0,
      },
      timer: null,
      getNewDataTimer: null,
      updating: false,
    }
  },

  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
    if (this.roulleteTimer) {
      clearInterval(this.roulleteTimer)
    }
    if (this.getNewDataTimer) {
      clearInterval(this.getNewDataTimer)
    }
  },

  mounted() {
    this.getData()
    this.startClock()
    this.startGetNewDataTimer()
  },

  methods: {
    startClock() {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.timer = setInterval(() => {
        let now = new Date()
        this.timeInfo = {
          monthDay: now.getDate(),
          month: now.getMonth(),
          year: now.getFullYear(),
          hours: now.getHours(),
          minutes: now.getMinutes(),
        }
      }, 1000)
    },
    async getData() {
      if (this.updating) return
      this.updating = true
      console.log('get new data')
      //utils.tryAndCatch(this, async () => {
      try {
        let result = await ServiceEvents.getEvent()
        console.log('event result', result)
        let data = result.data[0]
        if (this.pageData == null || this.pageData.updated != data.updated) {
          this.pageData = data
          //get the schedule data
          this.eventsData = data.pages.find(page => page.type === 'programme') || null
          this.startRoulleteTimer()
        }
      } catch (e) {
        console.log('error', e)
      } finally {
        this.updating = false
      }
      //})
    },
    changeDay() {
      if (this.eventsData) {
        let newday = this.day + 1
        if (newday > this.eventsData.content.length - 1) newday = 0
        this.day = newday
      }
    },
    startRoulleteTimer() {
      if (this.roulleteTimer) clearInterval(this.roulleteTimer)
      let updateTime = localStorage.getItem('carrousel-time') ? parseInt(localStorage.getItem('carrousel-time')) : 0
      if (updateTime == 0) {
        //detect the current day and select it
        let now = new Date()
        let day = now.getDate()
        //TODO: FAKE DAY
        //let day = 13
        let month = now.getMonth()
        let year = now.getFullYear()
        let events = this.eventsData.content
        let index = events.findIndex(event => {
          console.log('event-search', event)
          let firstEventOfTheDayDateParts = event.events[0].start.split(' ')[0].split('-')
          let date = new Date()
          date.setDate(firstEventOfTheDayDateParts[2])
          date.setMonth(firstEventOfTheDayDateParts[1] - 1)
          date.setFullYear(firstEventOfTheDayDateParts[0])
          return date.getDate() == day && date.getMonth() == month && date.getFullYear() == year
        })
        if (index >= 0) this.day = index
        return
      }
      this.roulleteTimer = setInterval(() => {
        this.changeDay()
      }, updateTime)
    },
    startGetNewDataTimer() {
      if (this.getNewDataTimer) clearInterval(this.getNewDataTimer)
      let updateTime = localStorage.getItem('update-time') ? parseInt(localStorage.getItem('update-time')) : 0
      if (updateTime > 0) {
        this.getNewDataTimer = setInterval(() => {
          this.getData()
        }, updateTime)
      }
    },
  },
}
</script>
