<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    //get query params from url and save them to local storage
    let urlParams = new URLSearchParams(window.location.search)
    let service = urlParams.get('service')
    if (service == 'agenda') {
      localStorage.setItem('pages-token', 'b7f30645db862abadb6be1405d4f59e9aaacfc0269')
      localStorage.setItem('event-id', '1088866')
      localStorage.setItem('device-mode', 'agenda')
      localStorage.setItem('footer-url', 'https://app.sppcr.pt')
      localStorage.setItem('update-time', 50000)
      localStorage.setItem('carrousel-time', 0)
      window.location.href = '/'
    } else if (service == 'employee-time-clock') {
      let accessKey = urlParams.get('access-key')
      localStorage.setItem('access-key', accessKey)
      localStorage.setItem('device-mode', 'employee-time-clock')
      window.location.href = '/'
    }
  },
}
</script>
