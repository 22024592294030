<template>
  <div class="flex flex-col gap-5 h-full">
    <div v-if="!isActivated" class="flex flex-col gap-5 h-full">
      <div>
        <fw-heading size="h1" muted>Ativar serviço de agenda de evento</fw-heading>
        <fw-label class="my-3">Token Pages</fw-label>
        <b-input v-model="pagesToken" size="is-medium" :disabled="loading" placeholder="Token da UC Pages" />
        <fw-label class="my-3">Id do evento</fw-label>
        <b-input v-model="eventId" size="is-medium" :disabled="loading" placeholder="Id do evento" />
        <fw-label class="my-3">Url do rodapé</fw-label>
        <b-input v-model="footerUrl" size="is-medium" :disabled="loading" placeholder="Url do rodapé" />
        <fw-label class="my-3">Intervalo de tempo para recolher novos dados (ms)</fw-label>
        <b-input
          v-model="updateTime"
          size="is-medium"
          :disabled="loading"
          placeholder="Tempo para ir buscar nova info"
        />
        <fw-label class="my-3">Intervalo de tempo para mudar de dia na agenda (ms)</fw-label>
        <b-input
          v-model="carrouselTime"
          size="is-medium"
          :disabled="loading"
          placeholder="Tempo para mudar de dia na agenda"
        />
      </div>
      <div class="flex-1"></div>
      <div>
        <fw-button
          :type="canActivate ? 'primary' : 'disabled'"
          :loading="loading"
          size="lg"
          expanded
          :disabled="!canActivate"
          @click.native="activateDevice"
          >Ativar serviço</fw-button
        >
      </div>
    </div>
    <div v-else class="text-center flex flex-col gap-10">
      <div class="text-primary font-bold text-xl">Serviço ativado</div>
      <div class="text-gray-500">Event id: {{ localEventId }}</div>
      <div class="text-gray-500">Pages token: (ocultado por segurança)</div>
      <div>
        <fw-button type="link-muted" @click.native="resetDevice">Reset device</fw-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ViewConfigurationAgenda',

  data() {
    return {
      loading: false,
      pagesToken: null,
      eventId: null,
      footerUrl: null,
      updateTime: 0,
      carrouselTime: 5000,
    }
  },

  computed: {
    localPagesToken() {
      return localStorage.getItem('pages-token')
    },
    localEventId() {
      return localStorage.getItem('event-id')
    },
    localFooterUrl() {
      return localStorage.getItem('footer-url')
    },
    localUpdateTime() {
      return localStorage.getItem('update-time')
    },
    localCarrouselTime() {
      return localStorage.getItem('carrousel-time')
    },
    isActivated() {
      return localStorage.getItem('device-mode') === 'agenda' && this.localPagesToken && this.localEventId
    },
    canActivate() {
      return this.pagesToken !== null && this.pagesToken !== '' && this.eventId !== null && this.eventId !== ''
    },
  },

  methods: {
    async activateDevice() {
      if (!this.canActivate) return
      localStorage.setItem('pages-token', this.pagesToken)
      localStorage.setItem('event-id', this.eventId)
      localStorage.setItem('device-mode', 'agenda')
      if (this.footerUrl && this.footerUrl.lenght != 0) localStorage.setItem('footer-url', this.footerUrl)
      if (this.updateTime && this.updateTime != 0) localStorage.setItem('update-time', this.updateTime)
      if (this.carrouselTime && this.carrouselTime != 0) localStorage.setItem('carrousel-time', this.carrouselTime)
      this.$router.go()
    },
    resetDevice() {
      this.$buefy.dialog.confirm({
        message: 'Tem a certeza que pretende limpar a configuração atual do dispositivo?',
        confirmText: 'Sim',
        cancelText: 'Não',
        type: 'is-danger',
        onConfirm: () => {
          localStorage.removeItem('pages-token')
          localStorage.removeItem('event-id')
          localStorage.removeItem('device-mode')
          localStorage.removeItem('footer-url')
          localStorage.removeItem('update-time')
          localStorage.removeItem('carrousel-time')
          this.$router.go()
        },
      })
    },
  },
}
</script>
