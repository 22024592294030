import axios from 'axios'
export default {
  getEvent() {
    const instance = axios.create({
      baseURL: process.env.VUE_APP_UCPAGES,
      withCredentials: false,
      headers: {
        'accept-language': 'pt',
      },
    })
    instance.defaults.headers.common['Authorization'] = localStorage.getItem('pages-token')
    return instance.get('/get-event-app?key=' + localStorage.getItem('event-id'))
  },
}
