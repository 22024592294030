<template>
  <div class="flex flex-col gap-5 h-full">
    <div v-if="!isActivated" class="flex flex-col gap-5 h-full">
      <div>
        <fw-heading size="h1" muted>Ativar serviço de marcação</fw-heading>
        <fw-label class="my-3">Código de ativação</fw-label>
        <b-input v-model="activationCode" size="is-medium" :disabled="loading" placeholder="Código de Ativação" />
      </div>
      <div class="flex-1"></div>
      <div>
        <fw-button
          :type="canActivate ? 'primary' : 'disabled'"
          :loading="loading"
          size="lg"
          expanded
          :disabled="!canActivate"
          @click.native="activateDevice"
          >Ativar serviço</fw-button
        >
      </div>
    </div>
    <div v-else class="text-center flex flex-col gap-10">
      <div class="text-primary font-bold text-xl">Serviço ativado</div>
      <div class="text-gray-500">{{ accessKey }}</div>
      <div>
        <fw-button type="link-muted" @click.native="resetDevice">Reset device</fw-button>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceMyUC from '@/services/ServiceMyUC'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  name: 'ViewConfigurationEmployeeTimeClock',

  data() {
    return {
      loading: false,
      activationCode: null,
    }
  },

  computed: {
    accessKey() {
      return localStorage.getItem('access-key')
    },
    isActivated() {
      return localStorage.getItem('device-mode') === 'employee-time-clock' && this.accessKey
    },
    canActivate() {
      return this.activationCode !== null && this.activationCode !== '' && this.activationCode.length > 3
    },
  },

  methods: {
    async activateDevice() {
      this.loading = true

      utils.tryAndCatch(
        this,

        // Do the thing
        async () => {
          let result = await ServiceMyUC.activateEntryTablet({
            activation_key: this.activationCode,
          })
          if (result.access_key) {
            localStorage.setItem('access-key', result.access_key)
            localStorage.setItem('device-mode', 'employee-time-clock')
            this.$router.go()
          } else {
            this.$buefy.alert.open({
              message: 'Código de ativação inválido',
              type: 'is-danger',
            })
          }
        },

        // Finally code
        () => {
          this.loading = false
        },

        // Deal with 404 / api error when activation code is invalid
        error => {
          this.$buefy.alert.open({
            message: error.message,
            type: 'is-danger',
          })
        }
      )
    },
    resetDevice() {
      this.$buefy.dialog.confirm({
        message: 'Tem a certeza que pretende limpar a configuração atual do dispositivo?',
        confirmText: 'Sim',
        cancelText: 'Não',
        type: 'is-danger',
        onConfirm: () => {
          localStorage.removeItem('access-key')
          localStorage.removeItem('device-mode')
        },
      })
    },
  },
}
</script>
