<template>
  <div class="absolute top-0 bottom-0 left-0 right-0 flex flex-col">
    <div class="flex flex-col">
      <div class="flex justify-end gap-10 px-12 pt-12 pb-9 items-center text-4xl font-bold h-22">
        <div>
          {{ monthDay }} {{ monthShort }} <span class="opacity-50">{{ yearShort }}</span>
        </div>
        <div>{{ formatedHours }}:{{ formatedMinutes }}</div>
      </div>
      <div class="flex px-12 pt-6 pb-6 items-center">
        <div class="bg-green-agenda text-black text-5xl font-black px-6 py-5 flex-shrink-0">
          Dia {{ humanEventDay }}
        </div>
        <div class="flex-1 px-6 text-white text-2xl font-black uppercase">
          {{ page.title }}
        </div>
      </div>
    </div>
    <div id="timeline" class="flex-1 overflow-y-auto overflow-x-visible no-scrollbar mx-12">
      <div class="flex flex-col" style="width: 99%">
        <EventComponent
          v-for="(evt, e) in events"
          :key="'event_' + e"
          :time-info="timeInfo"
          class="event_container"
          :event="evt"
        />
      </div>
    </div>
    <div v-if="footerLink" class="flex p-12 text-3xl font-semibold text-green-agenda items-center hidden">
      <div class="flex-1">
        Explore mais em
        <div class="text-5xl mt-1">{{ footerLinkWithoutProtocol }}</div>
      </div>
      <div class="p-2 bg-black rounded-md">
        <qrcode-vue
          :foreground="'#64EFAB'"
          :background="'#000000'"
          :value="footerLink"
          :size="100"
          level="L"
        ></qrcode-vue>
      </div>
    </div>
    <div
      v-if="gradientBottom"
      class="absolute z-40 left-0 right-0 h-32 bg-gradient-to-t from-gray-900 to-transparent"
      :class="{ 'bottom-0': !footerLink, 'bottom-0': footerLink }"
    ></div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import EventComponent from '@/components/EventComponent.vue'
export default {
  name: 'AgendaComponent',
  components: {
    EventComponent,
    QrcodeVue,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    day: {
      type: Number,
      required: true,
    },
    page: {
      type: Object,
      required: true,
    },
    timeInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      scrollToHappeningTimer: null,
      gradientBottom: false,
      gradientTop: false,
    }
  },
  computed: {
    footerLinkWithoutProtocol() {
      return this.footerLink ? this.footerLink.replace(/(^\w+:|^)\/\//, '') : null
    },
    footerLink() {
      return localStorage.getItem('footer-url') ? localStorage.getItem('footer-url') : null
    },
    monthDay() {
      return this.timeInfo.monthDay
    },
    month() {
      return this.timeInfo.month
    },
    year() {
      return this.timeInfo.year
    },
    hours() {
      return this.timeInfo.hours
    },
    minutes() {
      return this.timeInfo.minutes
    },
    monthShort() {
      let labels = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
      return labels[this.month]
    },
    yearShort() {
      return this.year.toString().substr(2, 2)
    },
    humanEventDay() {
      return this.day + 1
    },
    dayData() {
      return this.event ? this.event.content[this.day] : null
    },
    formatedHours() {
      return this.hours.toString().padStart(2, '0')
    },
    formatedMinutes() {
      return this.minutes.toString().padStart(2, '0')
    },
    events() {
      return this.dayData ? this.dayData.events : []
    },
  },

  mounted() {
    this.scrollToCurrentEvent()
    this.startScrollToHappeningTimer()
    //add listener to timeline scroll
    document.getElementById('timeline').addEventListener('scroll', this.checkTimelineScroll)
  },

  beforeDestroy() {
    if (this.scrollToHappeningTimer) clearInterval(this.scrollToHappeningTimer)
    //remove listener to timeline scroll
    document.getElementById('timeline').removeEventListener('scroll', this.checkTimelineScroll)
  },

  methods: {
    //check if div with id timeline is scrolled to the top or bottom
    checkTimelineScroll() {
      let timeline = document.getElementById('timeline')
      //check if there is overflow
      if (timeline.scrollHeight > timeline.clientHeight) {
        //check if scrolled to the top
        if (timeline.scrollTop == 0) {
          //show gradient on the bottom
          this.gradientBottom = true
        }
        //check if scrolled to the bottom
        else if (timeline.scrollTop + timeline.clientHeight == timeline.scrollHeight) {
          //show gradient on the top
          this.gradientTop = true
          this.gradientBottom = false
        } else {
          //hide gradient
          this.gradientTop = false
          this.gradientBottom = true
        }
      }
    },

    //scrollToCurrentEvent every 5 minutes
    startScrollToHappeningTimer() {
      if (this.scrollToHappeningTimer) clearInterval(this.scrollToHappeningTimer)
      this.scrollToHappeningTimer = setInterval(() => {
        this.scrollToCurrentEvent()
      }, 5 * 60 * 1000)
    },
    scrollToCurrentEvent() {
      //next tick
      this.$nextTick(() => {
        //check if there is any is-happening class
        let isHappening = document.querySelector('.is-happening')
        let isFuture = document.querySelector('.is-future')
        if (isHappening) {
          console.log('scrolling to is-happening')
          //scroll to is-happening, top of the screen
          isHappening.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            //block: 'center',
          })
        } else if (isFuture) {
          console.log('scrolling to is-future')
          //scroll to is-future
          isFuture.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        } else {
          //scroll to top
          let firstEvent = document.querySelector('.event_container')
          firstEvent.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }
      })
    },
  },
}
</script>

<style>
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>
