import Api from '@/fw-modules/fw-core-vue/api/Api'
import FwEnvConfig from '@/fw-modules/fw-core-vue/config'

export default {
  base(config) {
    let api = Api(config)
    api.defaults.baseURL = FwEnvConfig.apiUrlMyUC
    return api
  },

  baseUrl() {
    return FwEnvConfig.apiUrlMyUC
  },

  async getPhoneCountries() {
    const response = await this.base().get('/api/v1/countries')
    return response.data
  },

  async getSAPzipcodes() {
    const response = await this.base().get('/api/v1/zip-codes')
    return response.data
  },

  async validateSAPzipcodes(zipcode, params) {
    const response = await this.base().post('/api/v1/zip-codes', { zipcode }, { params })
    return response.data
  },

  // User info
  async getUserInfo() {
    const response = await this.base().get('/api/v1/user')
    return response.data
  },

  async updateUserInfo(payload, params) {
    const response = await this.base().post('/api/v1/user', payload, { params })
    return response.data
  },

  async getUserRoles() {
    const response = await this.base().get('/api/v1/user/roles')
    return response.data
  },

  // Training certificates
  async getUserEnrollments(params) {
    const response = await this.base().get('/api/v1/enrollments', { params })
    return response.data
  },

  async getUserAbsences(params) {
    const response = await this.base().get('/api/v1/user/absences', { params })
    return response.data
  },

  async getUserStats(params) {
    const response = await this.base().get('/api/v1/user/stats', { params })
    return response.data
  },

  async getUserAttendances(params) {
    const response = await this.base().get('/api/v1/user/attendances', { params })
    return response.data
  },

  async toggleUserAttendances(payload) {
    const response = await this.base().post('/api/v1/user/attendances/toggle', payload)
    return response.data
  },

  async getWorkerBalance(params) {
    const response = await this.base().get('/api/v1/worker/about/balance', { params })
    return response.data
  },

  async getUserReceipts(params) {
    const response = await this.base().get(`/api/v1/user/receipts`, { params })
    return response.data
  },

  async getUserBalances(params) {
    const response = await this.base().get(`api/v1/user/balances`, { params })
    return response.data
  },

  async setUserReceiptSeen(receiptKey, seen = true) {
    const response = await this.base().post(`/api/v1/user/receipt/${receiptKey}`, { seen: seen })
    return response.data
  },

  async getUserWeekEvents(weekNumber, year, payload = {}) {
    const response = await this.base().get(`/api/v1/user/events/week/${weekNumber}/${year}`, { params: payload })
    return response.data
  },

  async getManagerWeekEvents(weekNumber, year, payload = {}) {
    const response = await this.base().get(`/api/v1/manage/events/week/${weekNumber}/${year}`, { params: payload })
    return response.data
  },

  // ActivityAPI handler
  async getActivityLogs(params) {
    console.log('getActivityLogs params:>> ', params)
    if (params.context == 'year') {
      const response = await this.base().get(`/api/v1/manage/maps/year/${params.year}/activity`, { params })
      return response.data
    }
    // if (params.context == 'justification') return await this.getJustificationActivityLogs(params.context_key, params)

    const response = await this.base().get('/api/v1/activity', { params })
    return response.data
  },

  // NotificationsAPI handler
  async getNotifications(params) {
    console.log('getNotifications params:>> ', params)
    // if (params.item_type == 'justification') return await this.getJustificationNotifications(params.item_key, params)
    if (params.context == 'year') {
      const response = await this.base().get(`/api/v1/manage/maps/year/${params.year}/notifications`, { params })
      return response.data
    }

    const response = await this.base().get('/api/v1/notifications', { params })
    return response.data
  },

  async getNotification(key) {
    const response = await this.base().get(`/api/v1/notifications/${key}`)
    return response.data
  },

  async getUserJustifications(params) {
    const response = await this.base().get(`/api/v1/user/justifications`, { params })
    return response.data
  },

  async addUserJustification(payload) {
    const response = await this.base().post(`/api/v1/user/justifications`, payload)
    return response.data
  },

  async getUserJustification(justificationKey, params) {
    const response = await this.base().get(`/api/v1/user/justifications/${justificationKey}`, {
      params,
    })
    return response.data
  },

  async getJustificationActivityLogs(justificationKey, payload) {
    const response = await this.base().get(`/api/v1/justifications/${justificationKey}/activity`, { params: payload })
    return response.data
  },
  async getJustificationNotifications(justificationKey, payload) {
    const response = await this.base().get(`/api/v1/justifications/${justificationKey}/notifications`, {
      params: payload,
    })
    return response.data
  },

  async editUserJustification(justificationKey, payload) {
    const response = await this.base().post(`/api/v1/user/justifications/${justificationKey}`, payload)
    return response.data
  },

  async editUserJustificationStatus(justificationKey, payload) {
    const response = await this.base().post(`/api/v1/user/justifications/${justificationKey}/status`, payload)
    return response.data
  },

  async deleteUserJustification(justificationKey) {
    const response = await this.base().delete(`/api/v1/user/justifications/${justificationKey}`)
    return response.data
  },

  async getUserJustificationComments(justificationKey, params) {
    const response = await this.base().get(`/api/v1/user/justifications/${justificationKey}/comments`, {
      params,
    })
    return response.data
  },

  async addUserJustificationComment(justificationKey, payload) {
    const response = await this.base().post(`/api/v1/user/justifications/${justificationKey}/comments`, payload)
    return response.data
  },

  async getJustifications(params) {
    const response = await this.base().get(`/api/v1/justifications`, { params })
    return response.data
  },

  async getJustification(justificationKey, params) {
    const response = await this.base().get(`/api/v1/justifications/${justificationKey}`, {
      params,
    })
    return response.data
  },

  async getJustificationComments(justificationKey, params) {
    const response = await this.base().get(`/api/v1/justifications/${justificationKey}/comments`, { params })
    return response.data
  },

  async addJustificationComment(justificationKey, payload) {
    const response = await this.base().post(`/api/v1/justifications/${justificationKey}/comments`, payload)
    return response.data
  },

  async updateJustificationStatus(justificationKey, payload) {
    const response = await this.base().post(`/api/v1/justifications/${justificationKey}/status`, payload)
    return response.data
  },
  // Register entry/exit of the user
  async saveUserPresence(accessKey, device = 'tablet') {
    const response = await this.base().post(`/api/v1/user/attendances`, {
      action: 'entry',
      origin: device,
      access_key: accessKey,
      confirm: true,
    })
    return response.data
  },
  async verifyUserPresence(accessKey, device = 'tablet') {
    const response = await this.base().post(`/api/v1/user/attendances`, {
      action: 'entry',
      origin: device,
      access_key: accessKey,
      confirm: false,
    })
    return response.data
  },
  //activate entry registration tablet
  async activateEntryTablet(payload) {
    const response = await this.base().post(`/api/v1/tablets`, payload)
    return response.data
  },
  //subscribe to entry events in a tablet
  async subscribeTabletEvents(connectionId, accessKey) {
    const response = await this.base().post(`/v1/ws`, {
      code: 'subscribe_attendance',
      connectionId: connectionId,
      body: {
        access_key: accessKey,
      },
    })
    return response.data
  },
  //unsubscribe to entry events in a tablet
  async unsubscribeTabletEvents(connectionId, accessKey) {
    const response = await this.base().post(`/v1/ws`, {
      code: 'unsubscribe_attendance',
      connectionId: connectionId,
      body: {
        access_key: accessKey,
      },
    })
    return response.data
  },

  // Manage
  async getManagerMaps(params) {
    const response = await this.base().get('/api/v1/manage/maps', { params })
    return response.data
  },

  async updateManagerMaps(payload) {
    const response = await this.base().post('/api/v1/manage/maps', payload)
    return response.data
  },

  async getManagerMapsStats(params) {
    const response = await this.base().get('/api/v1/manage/maps/stats', { params })
    return response.data
  },

  async getManagerMapStats(mapKey, params) {
    const response = await this.base().get(`/api/v1/manage/maps/${mapKey}/stats`, { params })
    return response.data
  },

  async approveManagerMapUsers(mapKey) {
    const response = await this.base().post(`/api/v1/manage/maps/${mapKey}/status/maps_users`)
    return response.data
  },

  async getManagerMapsYearStats(year, params) {
    const response = await this.base().get(`/api/v1/manage/maps/stats/${year}`, { params })
    return response.data
  },

  async getPendingActions(params) {
    const response = await this.base().get('/api/v1/manage/inbox', { params })
    // const response = await this.base().get('/api/v1/manage/pending-actions', { params })
    return response.data
  },

  async getManageMap(mapKey) {
    const response = await this.base().get(`/api/v1/manage/maps/${mapKey}`)
    return response.data
  },

  async getManagerMapsUsers(params) {
    const response = await this.base().get(`/api/v1/manage/maps_users`, { params })
    return response.data
  },

  async getManagerMapUsers(mapKey, params) {
    const response = await this.base().get(`/api/v1/manage/maps/${mapKey}/maps_users`, { params })
    return response.data
  },

  async getManagerMapUser(mapKey, makUserKey, userKey, params) {
    const response = await this.base().get(`/api/v1/manage/maps/${mapKey}/maps_users/${userKey}/${makUserKey}`, {
      params,
    })
    return response.data
  },

  async updatedManagerMapUserBlocked(mapKey, makUserKey, userKey, blocked) {
    const response = await this.base().post(`/api/v1/manage/maps/${mapKey}/maps_users/${userKey}/${makUserKey}`, {
      blocked,
    })
    return response.data
  },

  async updateManageUserMapStatus(mapKey, userMapKey, userKey, payload) {
    const response = await this.base().post(
      `/api/v1/manage/maps/${mapKey}/maps_users/${userKey}/${userMapKey}/status`,
      payload
    )
    return response.data
  },

  async getManageUserMapActivity(mapKey, userMapKey, userKey, params) {
    const response = await this.base().get(
      `/api/v1/manage/maps/${mapKey}/maps_users/${userKey}/${userMapKey}/activity`,
      {
        params,
      }
    )
    return response.data
  },

  async getManageUserMapComments(mapKey, userMapKey, userKey, params) {
    const response = await this.base().get(
      `/api/v1/manage/maps/${mapKey}/maps_users/${userKey}/${userMapKey}/comments`,
      {
        params: params,
      }
    )
    return response.data
  },

  async addManageUserMapComments(mapKey, userMapKey, userKey, payload) {
    const response = await this.base().post(
      `/api/v1/manage/maps/${mapKey}/maps_users/${userKey}/${userMapKey}/comments`,
      payload
    )
    return response.data
  },

  async editManageUserMapComments(mapKey, userMapKey, commentKey, userKey, payload) {
    const response = await this.base().put(
      `/api/v1/manage/maps/${mapKey}/maps_users/${userKey}/${userMapKey}/comments/${commentKey}`,
      payload
    )
    return response.data
  },
  async deleteManageUserMapComments(mapKey, userMapKey, userKey, commentKey) {
    const response = await this.base().delete(
      `/api/v1/manage/maps/${mapKey}/maps_users/${userKey}/${userMapKey}/comments/${commentKey}`
    )
    return response.data
  },

  // User
  async getUserInbox(params) {
    const response = await this.base().get('/api/v1/inbox', { params })
    return response.data
  },

  async getUserMaps(params) {
    const response = await this.base().get(`/api/v1/maps_users`, { params: params })
    return response.data
  },

  async getUserMap(userMapKey) {
    const response = await this.base().get(`/api/v1/maps_users/${userMapKey}`)
    return response.data
  },

  async createUserMapVersion(mapKey, motive) {
    const response = await this.base().post(`/api/v1/maps/${mapKey}/maps_users`, { motive })
    return response.data
  },

  async createManageUserMapVersion(mapKey, userKey, motive) {
    const response = await this.base().post(`/api/v1/manage/maps/${mapKey}/users/${userKey}`, { motive })
    return response.data
  },

  /**
   *
   * @param {string} userMapKey
   * @param {Array} days
   * {
        "days": [
            {
                "date": "2023-07-28",
                "type": "allday"
            }
        ]
    }
   * @returns same as getUserMap
   */
  async addUserMapDays(userMapKey, days) {
    // { "days": [{"date": "2020-04-12", "type": "allday"}, ...]}
    const response = await this.base().post(`/api/v1/maps_users/${userMapKey}/days`, { days: days })
    return response.data
  },

  async addManageUserMapDays(mapKey, userMapKey, userKey, days) {
    // { "days": [{"date": "2020-04-12", "type": "allday"}, ...]}
    const response = await this.base().post(`/api/v1/manage/maps/${mapKey}/maps_users/${userKey}/${userMapKey}/days`, {
      days: days,
    })
    return response.data
  },

  /**
   *
   * @param {string} userMapKey
   * @param {Array} days list of dates to delete
   * @returns same as getUserMap
   */
  async deleteUserMapDays(userMapKey, days) {
    const response = await this.base().delete(`/api/v1/maps_users/${userMapKey}/days`, {
      data: { days: days },
    })
    return response.data
  },

  async deleteManageUserMapDays(mapKey, userMapKey, userKey, days) {
    const response = await this.base().delete(
      `/api/v1/manage/maps/${mapKey}/maps_users/${userKey}/${userMapKey}/days`,
      {
        data: { days: days },
      }
    )
    return response.data
  },

  /**
   *
   * @param {string} userMapKey
   * @param {Array} days
   * {
        "days": [
            {
                "date": "2023-07-28",
                "type": "allday",
            }
        ]
    }
   * @returns same as getUserMap
   */
  async updateUserMapDays(userMapKey, days) {
    const response = await this.base().put(`/api/v1/maps_users/${userMapKey}/days`, { days: days })
    return response.data
  },

  async updateManageUserMapDays(mapKey, userMapKey, userKey, days) {
    const response = await this.base().put(`/api/v1/manage/maps/${mapKey}/maps_users/${userKey}/${userMapKey}/days`, {
      days: days,
    })
    return response.data
  },

  async updateUserMapStatus(userMapKey, status) {
    const response = await this.base().post(`/api/v1/maps_users/${userMapKey}/status`, { status })
    return response.data
  },

  async getUserMapComments(userMapKey, params) {
    const response = await this.base().get(`/api/v1/maps_users/${userMapKey}/comments`, { params: params })
    return response.data
  },

  async addUserMapComments(userMapKey, payload) {
    const response = await this.base().post(`/api/v1/maps_users/${userMapKey}/comments`, payload)
    return response.data
  },

  async deleteUserMapComments(userMapKey, commentKey) {
    const response = await this.base().post(`/api/v1/maps_users/${userMapKey}/comments/${commentKey}`)
    return response.data
  },

  async getSyncs(year) {
    const response = await this.base().get(`/api/v1/syncs`, { params: { year: year || new Date().getFullYear() } })
    return response.data
  },

  // Creates all starting years
  async populateYearMaps(year, initialLoad = true) {
    const response = await this.base().post(`/api/v1/populate_year_maps`, {
      year: year || new Date().getFullYear(),
      initial_load: initialLoad,
    })
    return response.data
  },

  // Sends to sap
  async syncMyUCdays(year, employeeNumbers = null, mapKey = null) {
    const response = await this.base().post(`/api/v1/sync_myuc_days`, {
      year: year || new Date().getFullYear(),
      employee_numbers: employeeNumbers,
      map_key: mapKey,
    })
    return response.data
  },

  // Syncs worker rights from sap
  async syncWorkerRights(year, employeeNumbers = null) {
    const response = await this.base().post(`/api/v1/sync_worker_rights`, {
      year: year || new Date().getFullYear(),
      employee_numbers: employeeNumbers,
    })
    return response.data
  },

  // Syncs maps permissions from sap
  async syncMapsPermissions(year) {
    const response = await this.base().post(`/api/v1/sync_maps_permissions`, {
      year: year || new Date().getFullYear(),
    })
    return response.data
  },
}
