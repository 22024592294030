<template>
  <div class="h-screen w-screen flex flex-col gap-20 bg-gray-900 p-12 text-white overflow-hidden">
    <div v-if="bgVideo" class="absolute left-0 top-0 w-screen h-screen opacity-50">
      <video autoplay muted loop class="object-cover h-full">
        <source :src="publicPath + 'video/screensaver-compressed.mp4'" type="video/mp4" />
      </video>
    </div>
    <header class="text-white z-10">
      <div>
        <app-logo
          :class="{
            'w-32': !screen,
            'w-48': screen,
          }"
        />
      </div>
    </header>
    <main class="flex-1 justify-center items-center">
      <slot name="main" />
    </main>
    <footer v-if="footer" class="flex flex-col gap-1 font-medium text-center z-10">
      <div class="text-xs opacity-40">
        <span class="uppercase mr-0.5">{{ appName }} {{ appVersion }}</span>
        <span class="mx-0.5">By <a href="https://ucframework.pt" target="_blank">UC Framework</a></span>
      </div>
      <div class="text-sm opacity-70">{{ currentYear }} © University of Coimbra</div>
    </footer>
  </div>
</template>

<script>
import AppLogo from '@/components/AppLogo.vue'

export default {
  name: 'Layout',

  components: {
    AppLogo,
  },

  props: {
    bgVideo: {
      type: Boolean,
      default: false,
    },
    footer: {
      type: Boolean,
      default: true,
    },
    screen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      appVersion: process.env.VUE_APP_VERSION,
      appName: process.env.VUE_APP_KEY,
      publicPath: process.env.BASE_URL,
    }
  },

  computed: {
    currentYear() {
      const now = new Date()
      return now.getFullYear()
    },
  },
}
</script>
